<template>
  <b-card class="mb-4" no-body>
    <template #header>
      <h4 v-html="$t(tKey + 'header')" />
    </template>
    <b-card-body>
      <b-table
        class="small region-table"
        sticky-header
        small
        striped
        caption-top
        :caption="$t(tKey + 'caption')"
        :items="data"
        :fields="fields"
      >
        <template #cell(region)="data">
          <span
            :id="data.item.region"
            class="ml-1"
            v-html="
              data.item.region.length > 20
                ? data.item.region.substr(0, 20) + '...'
                : data.item.region
            "
          />
          <b-tooltip
            v-if="data.item.region.length > 20"
            :target="data.item.region"
            triggers="hover"
          >
            {{ data.item.region }}
          </b-tooltip>
        </template>
        <template #cell(overnights)="data">
          <p class="mb-1" v-html="$utils.formatToFixed(data.item.overnights)" />
          <p
            class="mb-1 font-weight-bold"
            :class="
              $utils.isPositive(data.item.overnights, data.item.overnightsBef)
                ? 'text-success'
                : 'text-danger'
            "
          >
            <font-awesome-icon
              :icon="
                $utils.isPositive(data.item.overnights, data.item.overnightsBef)
                  ? 'angle-up'
                  : 'angle-down'
              "
            />
            {{
              $utils.formatToFixed(
                Math.abs(
                  $utils.computeDifference(
                    data.item.overnights,
                    data.item.overnightsBef
                  )
                )
              )
            }}%
          </p>
        </template>
        <template #cell(market_share)="data">
            {{$utils.formatToFixed(data.item.market_share * 100) }}%
        </template>
      </b-table>
    </b-card-body>
  </b-card>
</template>

<script>
export default {
  name: "RegionTable",
  props: {
    data: { type: Array, required: true },
  },
  data() {
    return {
      tKey: "dashboard.region.",
    };
  },
  computed: {
    fields() {
      return [
        {
          key: "region",
          label: this.$t(this.tKey + "region"),
        },
        {
          key: "overnights",
          label: this.$t(this.tKey + "overnights"),
          sortable: true,
        },
        {
          key: "arrivals",
          label: this.$t(this.tKey + "arrivals"),
          formatter: (value) => this.$utils.formatToFixed(value),
          sortable: true,
        },
        {
          key: "market_share",
          label: this.$t(this.tKey + "market_share"),
          formatter: (value) => this.$utils.formatToFixed(value),
          sortable: true,
        },
      ];
    },
  },
};
</script>

<style lang="scss">
.destination-table {
  max-height: 75vh !important;
  & * {
    vertical-align: middle !important;
  }
}
</style>