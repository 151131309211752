var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-card',{staticClass:"mb-4",attrs:{"no-body":""},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('h4',{domProps:{"innerHTML":_vm._s(_vm.$t(_vm.tKey + 'header'))}})]},proxy:true}])},[_c('b-card-body',[_c('b-table',{staticClass:"small region-table",attrs:{"sticky-header":"","small":"","striped":"","caption-top":"","caption":_vm.$t(_vm.tKey + 'caption'),"items":_vm.data,"fields":_vm.fields},scopedSlots:_vm._u([{key:"cell(region)",fn:function(data){return [_c('span',{staticClass:"ml-1",attrs:{"id":data.item.region},domProps:{"innerHTML":_vm._s(
            data.item.region.length > 20
              ? data.item.region.substr(0, 20) + '...'
              : data.item.region
          )}}),(data.item.region.length > 20)?_c('b-tooltip',{attrs:{"target":data.item.region,"triggers":"hover"}},[_vm._v(" "+_vm._s(data.item.region)+" ")]):_vm._e()]}},{key:"cell(overnights)",fn:function(data){return [_c('p',{staticClass:"mb-1",domProps:{"innerHTML":_vm._s(_vm.$utils.formatToFixed(data.item.overnights))}}),_c('p',{staticClass:"mb-1 font-weight-bold",class:_vm.$utils.isPositive(data.item.overnights, data.item.overnightsBef)
              ? 'text-success'
              : 'text-danger'},[_c('font-awesome-icon',{attrs:{"icon":_vm.$utils.isPositive(data.item.overnights, data.item.overnightsBef)
                ? 'angle-up'
                : 'angle-down'}}),_vm._v(" "+_vm._s(_vm.$utils.formatToFixed( Math.abs( _vm.$utils.computeDifference( data.item.overnights, data.item.overnightsBef ) ) ))+"% ")],1)]}},{key:"cell(market_share)",fn:function(data){return [_vm._v(" "+_vm._s(_vm.$utils.formatToFixed(data.item.market_share * 100))+"% ")]}}])})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }